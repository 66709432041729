import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import appEn from './en/app.json';
import appPl from './pl/app.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: 'en',
    resources: {
      en: appEn,
      pl: appPl,
    },
    supportedLngs: ['en', 'pl'],
    react: {
      useSuspense: false,
    },
    detection: {
      lookupQuerystring: 'lng',
    },
  });

export function translate(key: string | string[], options?: any): string {
  return i18n.t(key, options) as string;
}

export default i18n;
