import * as Dialog from '@radix-ui/react-dialog';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const SuccessDialogService: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { t } = useTranslation('servicePopup');

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity" />
        <Dialog.Content className="fixed top-1/2 left-1/2 z-50 w-[90vw] lg:w-[50vw] p-6 bg-white rounded-md shadow-md transform -translate-x-1/2 -translate-y-1/2 focus:outline-none">
          <div className="text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto h-12 w-12 text-green-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <h3 className="text-lg font-semibold mt-4">{t('messageSentSuccessfully')}</h3>
            <p className="mt-2">{t('messageSentSuccessfullyContent')}</p>
            <button
              onClick={onClose}
              className="mt-4 px-4 py-2 bg-button text-white rounded hover:bg-hoverButton"
            >
              {t('close')}
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
SuccessDialogService.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.any.isRequired,
};
