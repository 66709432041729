import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import designerImg from '@/assets/designer.svg';
import developerImg from '@/assets/developer.svg';

import '../routes/Home.css';

export const LetsMakeHome = () => {
  const [init, setInit] = useState(false);

  const { t } = useTranslation('home');

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  return (
    <div className="grid md:gap-6 xl:gap-10 w-auto relative h-screen">
      {init && (
        <Particles
          id="tsparticles"
          options={{
            background: {
              color: {
                value: '#ffffff',
              },
            },
            fullScreen: {
              enable: false,
              zIndex: -1,
            },
            fpsLimit: 120,
            particles: {
              color: {
                value: '#aeaeae',
              },
              links: {
                color: '#aeaeae',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              move: {
                direction: 'none',
                enable: true,
                outModes: {
                  default: 'bounce',
                },
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: 'circle',
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
          className="absolute top-0 left-0 right-0 bottom-0 z-0 p-0 m-0"
        />
      )}

      <div className="text-4xl md:text-5xl xl:text-7xl font-bold w-auto h-48 md:h-auto pt-8 z-10">
        <div className="flex justify-evenly items-end h-12 xl:h-24">
          <div className="relative flex justify-evenly w-full  md:w-2/3 2xl:w-1/2 pt-2 xl:pt-7 ">
            <div className="absolute w-20 h-5 md:w-28 md:h-8 xl:w-32 xl:h-10 -left-2 -bottom-4 md:-left-9 md:-bottom-3 xl:-left-12 xl:-bottom-3 bg-button rounded-full">
              <p className="flex justify-center items-center w-20 h-5 md:w-28 md:h-7 xl:w-32 xl:h-10 text-white text-xs md:text-base xl:text-lg font-light uppercase">
                Designer
              </p>
              <img
                src={designerImg}
                className="absolute  -right-5 -bottom-1"
                alt="Designer Intechnity"
              />
            </div>
            <h1 className="mb-1 xl:mb-3">Elevate Your Brand</h1>
          </div>
        </div>
        <div className="grid grid-rows-1 h-32 md:h-24 mt-3">
          <div className="grid grid-rows-1 gap-1 md:flex md:justify-center md:items-center h-28 md:h-24 ">
            <h1 className="pr-5 xl:pr-10 text-center mt-3">With Our Custom</h1>
            <div className="flex justify-center">
              <div className="custom-frame relative inline-block w-40 md:w-auto px-2 xl:px-6 border border-red-500 ">
                <div className="custom-frame2">
                  <h1 className="text-[#7000FE] text-center pb-2 md:pb-4 xl:pb-6">Apps</h1>
                </div>
                <div className="absolute w-20 h-5 md:w-24 md:h-8 xl:w-32 xl:h-10 -right-16 -bottom-4 md:-right-16 md:-bottom-6 lg:-right-20 lg:-bottom-6 bg-[#01ACFE] rounded-full">
                  <p className="flex justify-center items-center w-20 h-5 md:w-24 md:h-7 xl:w-32 xl:h-10 text-white text-xs md:text-base xl:text-lg font-light">
                    Developer
                  </p>
                  <img
                    src={developerImg}
                    className="absolute -left-4 -top-2"
                    alt="Developer Intechnity"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center text-text text-sm md:text-base xl:text-xl text-center w-auto h-20 md:h-auto mx-5 md:mx-36 z-10">
        <p>{t('weCreate')}</p>
      </div>

      <div className="flex justify-center w-auto">
        <Link
          className="flex justify-center items-center sm:w-96 w-80 h-14 px-3 rounded-xl bg-button hover:bg-hoverButton text-white text-center text-lg font-semibold z-10"
          to={'/contact'}
        >
          {t('getOffer')}
        </Link>
      </div>
    </div>
  );
};
