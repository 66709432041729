import { useTranslation } from 'react-i18next';

import aws from '@/assets/logos/aws.png';
import azure from '@/assets/logos/azure.png';
import flutter from '@/assets/logos/flutter.png';
import google from '@/assets/logos/google.png';
import microsoft from '@/assets/logos/microsoft.png';
import netCore from '@/assets/logos/net-core.png';
import oracle from '@/assets/logos/oracle.png';
import reactNative from '@/assets/logos/react-native.png';

export const PoweringCompanies = () => {
  const { t } = useTranslation('home');

  return (
    <div className="flex justify-center items-center w-auto my-36 md:mt-28">
      <div className="grid grid-rows-1 gap-4 md:gap-6 xl:gap-10">
        <div className="flex justify-center items-center mb-5">
          <div className="text-xl md:text-4xl 2xl:text-4xl font-semibold mx-10 w-full lg:w-2/3 2xl:w-1/2 text-center">
            <h1>{t('powering')}</h1>
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center my-8">
          <img src={microsoft} className="my-4 mx-12 h-16" />
          <img src={google} className="my-4 mx-12 h-16" />
          <img src={oracle} className="my-4 mx-12 h-12" />
          <img src={reactNative} className="my-4 mx-12 h-16" />
          <img src={flutter} className="my-4 mx-12 h-16" />
          <img src={netCore} className="my-4 mx-12 h-16" />
          <img src={azure} className="my-4 mx-12 h-16" />
          <img src={aws} className="my-4 mx-12 h-16" />
        </div>
      </div>
    </div>
  );
};
