import { motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { ServicePopup } from './ServicePopup';
import { ServiceType } from './ServiceTypes';

import desktopAppDevelopmentImg from '@/assets/desktopAppDevelopment.svg';
import integrationsImg from '@/assets/integrations.svg';
import mobileAppDevelopmentImg from '@/assets/mobileAppDevelopment.svg';
import pluginDevelopmentImg from '@/assets/pluginDevelopment.svg';
import servicesImg from '@/assets/services.svg';
import softwareMaintenanceImg from '@/assets/softwareMaintenance.svg';
import webAppDevelopmentImg from '@/assets/webAppDevelopment.svg';

export const ServiceItems = () => {
  const { t } = useTranslation('services');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<ServiceType>(ServiceType.WEB_APP);

  const handleClickOpen = (service) => {
    setSelectedService(service);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'Service',
    serviceType: 'Software Development Services',
    provider: {
      '@type': 'Organization',
      name: 'Intechnity',
      url: 'https://intechnity.com',
    },
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Software Development Services',
      itemListElement: [
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: t(ServiceType.WEB_APP),
            description: t('webAppDevelopmentDescription1'),
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: t(ServiceType.MOBILE_APP),
            description: t('mobileAppDevelopmentDescription1'),
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: t(ServiceType.DESKTOP_APP),
            description: t('desktopAppDevelopmentDescription1'),
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: t(ServiceType.PLUGIN_DEVELOPMENT),
            description: t('pluginDevelopmentDescription1'),
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: t(ServiceType.INTEGRATIONS),
            description: t('integrationsDescription1'),
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: t(ServiceType.SOFTWARE_MAINTENANCE),
            description: t('softwareMaintenanceDescription1'),
          },
        },
      ],
    },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  const buttonVariants = {
    hidden: { scale: 0 },
    visible: { scale: 1, transition: { type: 'spring', stiffness: 100 } },
  };

  const alternatingImageVariants = {
    hidden: { scale: 0 },
    visible: { scale: 1, transition: { type: 'spring', stiffness: 100 } },
  };

  const AnimatedDiv = ({ children, animation }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
      triggerOnce: true,
      rootMargin: '-50% 0px',
    });

    if (inView) {
      controls.start('visible');
    }

    return (
      <motion.div ref={ref} initial="hidden" animate={controls} variants={animation}>
        {children}
      </motion.div>
    );
  };

  return (
    <div className="grid grid-rows-1 gap-10 my-10">
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      <AnimatedDiv animation={textVariants}>
        <div>
          <div className="flex justify-center">
            <motion.div
              className="flex items-center text-xs md:text-base 2xl:text-xl font-semibold uppercase bg-[#F2F2F3] px-7 py-3 rounded-full"
              variants={textVariants}
            >
              <img src={servicesImg} className="pr-2" alt="Services" />
              <h1>{t('services')}</h1>
            </motion.div>
          </div>
          <div className="flex justify-center items-center my-5 sm:my-12">
            <motion.h1
              className="text-xl md:text-4xl 2xl:text-4xl font-semibold"
              variants={textVariants}
            >
              {t('jumpServices')}
            </motion.h1>
          </div>
        </div>
      </AnimatedDiv>

      <div className="grid grid-rows-6 gap-8 px-10 sm:px-20 lg:px-28 xl:px-32 2xl:px-64">
        <AnimatedDiv animation={textVariants}>
          <div className="grid grid-col-1 sm:flex sm:justify-center bg-[#F0FCF5] p-10 rounded-3xl">
            <div className="grid grid-col-1 gap-4 xl:gap-6 sm:w-2/3">
              <motion.div
                className="text-lg sm:text-xl md:text-2xl xl:text-3xl"
                variants={textVariants}
              >
                <h1>{t(ServiceType.WEB_APP)}</h1>
              </motion.div>
              <motion.div
                className="text-[#6B6B6B] text-sm md:text-base xl:text-xl sm:pr-10"
                variants={textVariants}
              >
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5 sm:text-xl xl:text-2xl">
                  {t('crossPlatform')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5">
                  {t('webAppDevelopmentDescription1')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10">
                  {t('webAppDevelopmentDescription2')}
                </p>
              </motion.div>
              <motion.div variants={buttonVariants}>
                <button
                  onClick={() => handleClickOpen(ServiceType.WEB_APP)}
                  className="w-48 h-12 xl:h-14 rounded-xl bg-[#2DCA73] hover:bg-[#49E78F] text-white text-lg font-semibold"
                >
                  {t('learn')}
                </button>
                <ServicePopup open={dialogOpen} onClose={handleClose} service={selectedService} />
              </motion.div>
            </div>
            <motion.div
              className="flex justify-center pt-5 sm:pt-0 sm:w-1/3"
              variants={alternatingImageVariants}
            >
              <img src={webAppDevelopmentImg} alt="Web App Development" />
            </motion.div>
          </div>
        </AnimatedDiv>

        <AnimatedDiv animation={textVariants}>
          <div className="grid grid-col-1 sm:flex sm:justify-center bg-[#E8F8FF] p-10 rounded-3xl">
            <motion.div
              className="flex justify-center pb-5 sm:pt-0 sm:w-1/3"
              variants={alternatingImageVariants}
            >
              <img src={mobileAppDevelopmentImg} alt="Mobile App Development" />
            </motion.div>
            <div className="grid grid-col-1 gap-4 xl:gap-6 sm:pl-10 sm:w-2/3">
              <motion.div
                className="text-lg sm:text-xl md:text-2xl xl:text-3xl"
                variants={textVariants}
              >
                <h1>{t(ServiceType.MOBILE_APP)}</h1>
              </motion.div>
              <motion.div
                className="text-[#6B6B6B] text-sm md:text-base xl:text-xl"
                variants={textVariants}
              >
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5 sm:text-xl xl:text-2xl">
                  {t('optimizedExperience')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5">
                  {t('mobileAppDevelopmentDescription1')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10">
                  {t('mobileAppDevelopmentDescription2')}
                </p>
              </motion.div>
              <motion.div variants={buttonVariants}>
                <button
                  onClick={() => handleClickOpen(ServiceType.MOBILE_APP)}
                  className="w-48 h-12 xl:h-14 rounded-xl bg-[#01ACFE] hover:bg-[#5FCBFF] text-white text-lg font-semibold"
                >
                  {t('learn')}
                </button>
              </motion.div>
            </div>
          </div>
        </AnimatedDiv>

        <AnimatedDiv animation={textVariants}>
          <div className="grid grid-col-1 sm:flex sm:justify-center bg-[#DFCAF9] p-10 rounded-3xl">
            <div className="grid grid-col-1 gap-4 xl:gap-6 sm:w-2/3">
              <motion.div
                className="text-lg sm:text-xl md:text-2xl xl:text-3xl"
                variants={textVariants}
              >
                <h1>{t(ServiceType.DESKTOP_APP)}</h1>
              </motion.div>
              <motion.div
                className="text-[#6B6B6B] text-sm md:text-base xl:text-xl sm:pr-10"
                variants={textVariants}
              >
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5 sm:text-xl xl:text-2xl">
                  {t('robustSolutions')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5">
                  {t('desktopAppDevelopmentDescription1')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10">
                  {t('desktopAppDevelopmentDescription2')}
                </p>
              </motion.div>
              <motion.div variants={buttonVariants}>
                <button
                  onClick={() => handleClickOpen(ServiceType.DESKTOP_APP)}
                  className="w-48 h-12 xl:h-14 rounded-xl bg-[#7000FE] hover:bg-[#AE6FFF] text-white text-lg font-semibold"
                >
                  {t('learn')}
                </button>
              </motion.div>
            </div>
            <motion.div
              className="flex justify-center pt-5 sm:pt-0 sm:w-1/3"
              variants={alternatingImageVariants}
            >
              <img src={desktopAppDevelopmentImg} alt="Desktop App Development" />
            </motion.div>
          </div>
        </AnimatedDiv>

        <AnimatedDiv animation={textVariants}>
          <div className="grid grid-col-1 sm:flex sm:justify-center bg-[#FFF0F6] p-10 rounded-3xl">
            <motion.div
              className="flex justify-center pb-5 sm:pt-0 sm:w-1/3"
              variants={alternatingImageVariants}
            >
              <img src={pluginDevelopmentImg} alt="Plugin Development" />
            </motion.div>
            <div className="grid grid-col-1 gap-4 xl:gap-6 sm:pl-10 sm:w-2/3">
              <motion.div
                className="text-lg sm:text-xl md:text-2xl xl:text-3xl"
                variants={textVariants}
              >
                <h1>{t(ServiceType.PLUGIN_DEVELOPMENT)}</h1>
              </motion.div>
              <motion.div
                className="text-[#6B6B6B] text-sm md:text-base xl:text-xl"
                variants={textVariants}
              >
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5 sm:text-xl xl:text-2xl">
                  {t('extendedFunctionality')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5">
                  {t('pluginDevelopmentDescription1')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10">
                  {t('pluginDevelopmentDescription2')}
                </p>
              </motion.div>
              <motion.div variants={buttonVariants}>
                <button
                  onClick={() => handleClickOpen(ServiceType.PLUGIN_DEVELOPMENT)}
                  className="w-48 h-12 xl:h-14 rounded-xl bg-[#FF2C81] hover:bg-[#FF7FB2] text-white text-lg font-semibold"
                >
                  {t('learn')}
                </button>
              </motion.div>
            </div>
          </div>
        </AnimatedDiv>

        <AnimatedDiv animation={textVariants}>
          <div className="grid grid-col-1 sm:flex sm:justify-center bg-[#FFF6F3] p-10 rounded-3xl">
            <div className="grid grid-col-1 gap-4 xl:gap-6 sm:w-2/3">
              <motion.div
                className="text-lg sm:text-xl md:text-2xl xl:text-3xl"
                variants={textVariants}
              >
                <h1>{t(ServiceType.INTEGRATIONS)}</h1>
              </motion.div>
              <motion.div
                className="text-[#6B6B6B] text-sm md:text-base xl:text-xl sm:pr-10"
                variants={textVariants}
              >
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5 sm:text-xl xl:text-2xl">
                  {t('seamlessConnectivity')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5">
                  {t('integrationsDescription1')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10">
                  {t('integrationsDescription2')}
                </p>
              </motion.div>
              <motion.div variants={buttonVariants}>
                <button
                  onClick={() => handleClickOpen(ServiceType.INTEGRATIONS)}
                  className="w-48 h-12 xl:h-14 rounded-xl bg-[#FF7547] hover:bg-[#F99472] text-white text-lg font-semibold"
                >
                  {t('learn')}
                </button>
              </motion.div>
            </div>
            <motion.div
              className="flex justify-center pt-5 sm:pt-0 sm:w-1/3"
              variants={alternatingImageVariants}
            >
              <img src={integrationsImg} alt="Integrations" />
            </motion.div>
          </div>
        </AnimatedDiv>

        <AnimatedDiv animation={textVariants}>
          <div className="grid grid-col-1 sm:flex sm:justify-center bg-[#F9F9F9] p-10 rounded-3xl">
            <motion.div
              className="flex justify-center pb-5 sm:pt-0 sm:w-1/3"
              variants={alternatingImageVariants}
            >
              <img src={softwareMaintenanceImg} alt="Software Maintenance" />
            </motion.div>
            <div className="grid grid-col-1 gap-4 xl:gap-6 sm:pl-10 sm:w-2/3">
              <motion.div
                className="text-lg sm:text-xl md:text-2xl xl:text-3xl"
                variants={textVariants}
              >
                <h1>{t(ServiceType.SOFTWARE_MAINTENANCE)}</h1>
              </motion.div>
              <motion.div
                className="text-[#6B6B6B] text-sm md:text-base xl:text-xl"
                variants={textVariants}
              >
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5 sm:text-xl xl:text-2xl">
                  {t('optimalPerformance')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10 mb-5">
                  {t('softwareMaintenanceDescription1')}
                </p>
                <p className="leading-6 md:leading-8 xl:leading-10">
                  {t('softwareMaintenanceDescription2')}
                </p>
              </motion.div>
              <motion.div variants={buttonVariants}>
                <button
                  onClick={() => handleClickOpen(ServiceType.SOFTWARE_MAINTENANCE)}
                  className="w-48 h-12 xl:h-14 rounded-xl bg-[#84693B] hover:bg-[#B29159] text-white text-lg font-semibold"
                >
                  {t('learn')}
                </button>
              </motion.div>
            </div>
          </div>
        </AnimatedDiv>
      </div>
    </div>
  );
};
