import clsx from 'clsx';
import { Suspense, useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { Footer } from './Footer';

import logoImg from '@/assets/logo.svg';
import { ButtonScrollTop } from '@/components/ButtonScrollTop';
import { GetInTouch } from '@/components/GetInTouch';
import LanguagePicker from '@/components/LanguagePicker';
import { Spinner } from '@/components/Spinner';
import useScrollToTop from '@/hooks/useScrollToTop';
import i18n from '@/translations/i18n';

import './MainLayout.css';

export const MainLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const location = useLocation();
  const buttonColor = location.pathname === '/contact' ? 'bg-emerald-800' : 'bg-black';
  const hoverButtonColor =
    location.pathname === '/contact' ? 'hover:text-black' : 'hover:text-emerald-800';
  const getLinkClass = (path: string) => {
    return location.pathname === path ? 'text-emerald-800' : 'hover:text-emerald-800';
  };

  const { t } = useTranslation('navigation');

  const mainRef = useRef<HTMLElement>(null);
  useScrollToTop(mainRef);

  return (
    <div className="h-screen w-auto mx-0 flex overflow-hidden">
      <div className="relative flex flex-col w-0 flex-1 overflow-hidden">
        <div className="w-full h-24">
          <div
            className={clsx(
              'z-40 fixed top-0 left-0 w-full h-24 bg-white flex items-center justify-between px-4 sm:px-10 2xl:px-20 font-sans text-lg',
              { 'shadow-2xl': !isOpen }
            )}
          >
            <div className="flex items-center">
              <Link to="/">
                <img src={logoImg} className="w-fit h-fit" alt="Intechnity"></img>
              </Link>
            </div>

            <div className="z-40 flex">
              <div className="lg:hidden flex items-center">
                <button onClick={toggleMenu} className="text-black relative">
                  <div
                    className={`transition-transform duration-300 ease-in-out ${
                      isOpen ? 'rotate-180 opacity-0' : 'rotate-0 opacity-100'
                    }`}
                  >
                    <FaBars size={24} />
                  </div>
                  <div
                    className={`transition-transform duration-300 ease-in-out absolute top-0 left-0 ${
                      isOpen ? 'rotate-0 opacity-100' : 'rotate-180 opacity-0'
                    }`}
                  >
                    <FaTimes size={24} />
                  </div>
                </button>
              </div>

              <div className="hidden lg:flex text-gray-400 xl:text-base 2xl:text-lg text-center">
                <div className="flex justify-center items-center h-10">
                  <Link to="/" className={`px-5 py-3 uppercase ${getLinkClass('/')}`}>
                    {t('home')}
                  </Link>
                </div>
                <div className="flex justify-center items-center h-10">
                  <Link
                    to="/services"
                    className={`px-5 py-3 uppercase ${getLinkClass('/services')}`}
                  >
                    {t('services')}
                  </Link>
                </div>
                <div className="flex justify-center items-center h-10">
                  <Link
                    to="/who-we-are"
                    className={`px-5 py-3 uppercase ${getLinkClass('/who-we-are')}`}
                  >
                    {t('whoWeAre')}
                  </Link>
                </div>

                {/* TODO - enable pages */}
                {/* <div className="flex justify-center items-center h-10">
                  <Link
                    to="/products"
                    className={`xl:px-5 py-3 uppercase ${getLinkClass('/products')}`}
                  >
                    {t('products')}
                  </Link>
                </div>
                <div className="flex justify-center items-center h-10">
                  <Link
                    to="/portfolio"
                    className={`xl:px-5 py-3 uppercase ${getLinkClass('/portfolio')}`}
                  >
                    {t('portfolio')}
                  </Link>
                </div> */}

                <div className="flex justify-center items-center h-10">
                  <Link
                    to="/intechnity-one"
                    className={`px-5 py-3 uppercase ${getLinkClass('/intechnity-one')}`}
                  >
                    {t('intechnityOne')}
                  </Link>
                </div>
                <div className="flex justify-center items-center h-10">
                  <Link
                    to="/contact"
                    className={`py-3 px-8 ${buttonColor} text-white ${hoverButtonColor} font-medium rounded-xl uppercase relative contact-button`}
                  >
                    {t('contact')}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <div
            className={clsx(
              'fixed w-screen bg-white pb-5 transition-top duration-300 drop-shadow-md lg:hidden z-30 pt-8',
              {
                '-top-[800px] opacity-0': !isOpen,
                'top-20 opacity-100': isOpen,
              }
            )}
          >
            <div className="flex flex-col items-center text-gray-400 pr-10">
              <Link to="/" className={`px-3 py-2 uppercase ${getLinkClass('/')}`}>
                {t('home')}
              </Link>
              <Link to="/services" className={`px-3 py-2 uppercase ${getLinkClass('/services')}`}>
                {t('services')}
              </Link>
              <Link
                to="/who-we-are"
                className={`px-3 py-2 uppercase ${getLinkClass('/who-we-are')}`}
              >
                {t('whoWeAre')}
              </Link>

              {/* TODO - enable pages */}
              {/* <Link to="/products" className={`px-3 py-2 uppercase ${getLinkClass('/products')}`}>
                {t('products')}
              </Link>
              <Link to="/portfolio" className={`px-3 py-2 uppercase ${getLinkClass('/portfolio')}`}>
                {t('portfolio')}
              </Link> */}

              <Link
                to="/intechnity-one"
                className={`px-3 py-2 uppercase ${getLinkClass('/intechnity-one')}`}
              >
                {t('intechnityOne')}
              </Link>
              <Link
                to="/contact"
                className={`bg-black text-white hover:border-4 border-emerald-800 rounded-lg px-3 py-2 my-5 uppercase ${buttonColor} ${hoverButtonColor}`}
              >
                {t('contact')}
              </Link>
            </div>
          </div>
        </div>

        <div className="absolute bottom-3 right-3 z-50 flex justify-end bg-transparent">
          <div className="mr-3 lg:ml-3 text-text text-base bg-transparent">
            <LanguagePicker />
          </div>
        </div>
        <main
          className="flex flex-1 flex-col relative overflow-y-auto focus:outline-none"
          ref={mainRef}
        >
          <Suspense
            fallback={
              <div className="h-full w-full flex items-center justify-center">
                <Spinner size="xl" />
              </div>
            }
          >
            <ButtonScrollTop mainRef={mainRef} />
            <Outlet />
            <GetInTouch />
            <Footer />
          </Suspense>
        </main>
      </div>
    </div>
  );
};
