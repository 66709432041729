import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = (ref: React.RefObject<HTMLElement>) => {
  const location = useLocation();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, [location, ref]);
};

export default useScrollToTop;
