import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ServiceItems } from '../components/ServiceItems';

import serviceImg from '@/assets/service.svg';

export const Services = () => {
  const { t } = useTranslation('services');

  return (
    <div className="w-auto font-sans my-5">
      <div className="grid grid-rows-1 gap-8 w-auto">
        <div className="flex justify-center text-button text-base lg:text-xl w-auto">
          <h2>{t('services')}</h2>
        </div>
        <div className="text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl font-bold w-auto md:h-auto">
          <div className="flex justify-evenly items-end ">
            <div className="relative flex justify-evenly w-full px-10 text-center">
              <h1 className="mb-1 xl:mb-3">{t('customizableServices')}</h1>
            </div>
          </div>
        </div>
        <div className="grid grit-rows-2 text-text text-sm md:text-base xl:text-xl text-center w-auto mx-5 md:mx-48 2xl:mx-96">
          <p className="mb-5">{t('subtitle1')}</p>
          <p>{t('subtitle2')}</p>
        </div>
        <div className="flex justify-center w-auto my-3 lg:my-5">
          <Link
            className="flex justify-center items-center w-40 h-12 xl:w-44 xl:h-14 rounded-xl bg-button hover:bg-hoverButton text-white text-lg font-semibold"
            to={'/contact'}
          >
            {t('getOffer')}
          </Link>
        </div>
        <div className="flex justify-center px-10 lg:px-48">
          <img src={serviceImg} alt="Adaptable, Customizable Services" />
        </div>
      </div>

      <ServiceItems />
    </div>
  );
};
