import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogContent, DialogOverlay, DialogClose } from '@radix-ui/react-dialog';
import { addDoc, collection } from 'firebase/firestore';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { z } from 'zod';

import callImg from '@/assets/call.svg';
import emailImg from '@/assets/email.svg';
import facebookImg from '@/assets/facebook.svg';
import githubImg from '@/assets/github.svg';
import linkedinImg from '@/assets/linkedin.svg';
import locationsImg from '@/assets/location.svg';
import logoFooterImg from '@/assets/logo_footer.svg';
import { Map } from '@/components/GoogleMap';
import { database } from '@/utils/firebaseConfig';

import './MainLayout.css';

export const createEmailSchema = (t: TFunction) =>
  z.object({
    email: z.string().email(t('invalidEmail')),
  });

export const Footer = () => {
  const { t } = useTranslation('newsletter');

  const emailSchema = createEmailSchema(t);

  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof emailSchema>>({
    resolver: zodResolver(emailSchema),
  });

  const onSubmit = async (data: z.infer<typeof emailSchema>) => {
    try {
      await addDoc(collection(database, 'subscribers'), {
        email: data.email,
        subscribedAt: new Date(),
      });
      setDialogOpen(true);
    } catch (error) {
      console.error('Error subscribing to newsletter: ', error);
    }
  };

  const location = useLocation();
  const getLinkClass = (path: string) => {
    return location.pathname === path ? 'text-button' : 'hover:text-button';
  };

  const apiKey = 'AIzaSyB_7jY3eJGXGyAHXtcoidOJCT66ttBFdZ8';

  return (
    <footer className="w-full duration-200">
      <div className="w-full">
        <div className="bg-black w-full h-full px-5 md:px-20 text-white text-xs md:text-lg font-sans ">
          <div className="w-full pt-10 flex-col-reverse divide-y">
            <div className="flex justify-around items-center pb-5 xl:mx-32 2xl:mx-56">
              <div className="w-1/2 md:w-3/5 text-white">
                <h2 className="uppercase">Newsletter</h2>
                <h1 className="text-2xl md:text-4xl mt-3 font-medium">{t('stayInTheKnow')}</h1>
              </div>

              <form onSubmit={handleSubmit(onSubmit)} className="w-1/2 md:w-2/5">
                <div className="pb-3">
                  <input
                    {...register('email')}
                    type="email"
                    placeholder={t('enterEmailAddress')}
                    autoComplete="email"
                    required
                    className="block w-full bg-black rounded-lg border-0 px-4 py-3 text-white ring-1 ring-inset ring-white placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.email && <p className="text-red-500">{errors.email.message}</p>}
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full font-medium mt-2 rounded-lg py-3 bg-button hover:bg-hoverButton"
                  >
                    {t('subscribe')}
                  </button>
                </div>
              </form>

              {/* Dialog Component */}
              <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogOverlay className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
                <DialogContent className="fixed top-1/2 left-1/2 max-w-sm p-6 bg-white rounded-lg shadow-lg transform -translate-x-1/2 -translate-y-1/2">
                  <h2 className="text-xl text-black font-semibold">
                    {t('subscribedSuccessfully')}
                  </h2>
                  <p className="mt-4 text-black">{t('thankYouForSubscribing')}</p>
                  <DialogClose asChild>
                    <button
                      className="mt-6 w-full bg-button text-white rounded-lg py-2 hover:bg-hoverButton"
                      onClick={() => setDialogOpen(false)}
                    >
                      {t('close')}
                    </button>
                  </DialogClose>
                </DialogContent>
              </Dialog>
            </div>

            <div className="w-full pb-9">
              <div className="flex justify-center items-center pt-7">
                <img src={logoFooterImg} alt="Logo Intechnity" />
              </div>
              <div className="grid grid-cols-3 gap-1 xl:flex xl:justify-center xl:items-center w-full h-fit pt-7">
                <div className="flex justify-center items-center w-auto xl:w-64 h-10">
                  <Link to="/" className={`py-3 uppercase ${getLinkClass('/')}`}>
                    {t('home')}
                  </Link>
                </div>
                <div className="flex justify-center items-center w-auto xl:w-64 h-10">
                  <Link to="/services" className={`py-3 uppercase ${getLinkClass('/services')}`}>
                    {t('services')}
                  </Link>
                </div>
                <div className="flex justify-center items-center w-auto xl:w-64 h-10">
                  <Link
                    to="/who-we-are"
                    className={`py-3 uppercase ${getLinkClass('/who-we-are')}`}
                  >
                    {t('whoWeAre')}
                  </Link>
                </div>

                {/* TODO - enable pages */}
                {/* <div className="flex justify-center items-center w-auto xl:w-64 h-10">
                  <Link to="/products" className={`py-3 uppercase ${getLinkClass('/products')}`}>
                    {t('products')}
                  </Link>
                </div>
                <div className="flex justify-center items-center w-auto xl:w-64 h-10">
                  <Link to="/portfolio" className={`py-3 uppercase ${getLinkClass('/portfolio')}`}>
                    {' '}
                    {t('portfolio')}
                  </Link>
                </div> */}

                <div className="flex justify-center items-center w-auto xl:w-64 h-10">
                  <Link
                    to="/intechnity-one"
                    className={`py-3 uppercase ${getLinkClass('/intechnity-one')}`}
                  >
                    {t('intechnityOne')}
                  </Link>
                </div>
                <div className="flex justify-center items-center w-auto xl:w-64 h-10">
                  <Link to="/contact" className={`py-3 uppercase ${getLinkClass('/contact')}`}>
                    {t('contact')}
                  </Link>
                </div>
              </div>
              <div className="sm:relative sm:z-40 flex justify-center">
                <div className="sm:absolute sm:-bottom-48 lg:-bottom-32 2xl:-bottom-32 grid justify-center lg:grid lg:grid-cols-3 lg:divide-x w-2/3 lg:w-3/4 py-3 2xl:py-6 rounded-lg bg-black opacity-90">
                  <div className=" flex items-center sm:justify-center w-full ">
                    <img src={callImg} className="w-5 h-10" alt="Call Intechnity" />
                    <a
                      href="tel:+48536393755"
                      className="pl-2 py-3 text-base xl:text-lg hover:text-button font-sans"
                    >
                      +48 536 393 755
                    </a>
                  </div>
                  <div className="flex items-center sm:justify-center w-full ">
                    <img src={locationsImg} alt="Location Intechnity" />
                    <a
                      href="https://maps.app.goo.gl/7i6ZPQZL2s4V7dg16"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" pl-2 py-3 text-base xl:text-lg hover:text-button font-sans"
                    >
                      ul. Technologiczna 2, 45-839 Opole
                    </a>
                  </div>
                  <div className="flex items-center sm:justify-center w-full ">
                    <img src={emailImg} alt="Email Intechnity" />
                    <a
                      href="mailto:contact@intechnity.com"
                      className="pl-2 py-3 text-base xl:text-lg hover:text-button font-sans"
                    >
                      contact@intechnity.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Map apiKey={apiKey} />
        <div className="flex justify-between items-center w-full h-16 px-5 md:px-24 bg-button ">
          <div className="flex justify-between items-center">
            <p className="text-white">Copyright &#64; Intechnity</p>
          </div>
          <div className="flex text-white justify-between items-center pr-20 sm:pr-0">
            <div className="mr-4">
              <a href="https://www.facebook.com/intechnitycom">
                <img src={facebookImg} alt="Facebook Intechnity" />
              </a>
            </div>
            <div className="mr-4">
              <a href="https://github.com/Intechnity-com">
                <img src={githubImg} alt="GitHub Intechnity" />
              </a>
            </div>
            <div className="">
              <a href="https://www.linkedin.com/showcase/intechnitycom/">
                <img src={linkedinImg} alt="LinkedIn Intechnity" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
