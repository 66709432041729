import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import '../routes/Home.css';

const sections = [
  { id: 0, title: 'webAppDevelopmentService', content: 'webAppDevelopmentInfo' },
  { id: 1, title: 'mobileAppDevelopmentService', content: 'mobileAppDevelopmentInfo' },
  { id: 2, title: 'desktopAppDevelopmentService', content: 'desktopAppDevelopmentInfo' },
];

export const ServicesHome = () => {
  const { t } = useTranslation('home');
  const [activeIndex, setActiveIndex] = useState(null);

  const handleSetIndex = (id) => {
    setActiveIndex(id === activeIndex ? null : id);
  };

  return (
    <div>
      <div className="grid grid-cols-1 w-full px-10 sm:px-16 2xl:px-48 my-36">
        <div className="flex justify-center items-center mb-5">
          <div className="flex items-center text-xs md:text-base 2xl:text-xl font-semibold uppercase bg-[#F2F2F3] px-7 py-3 rounded-full">
            <FaHeart color="#ff0000" />
            <h1 className="ml-2">{t('services')}</h1>
          </div>
        </div>
        <div className="flex justify-center items-center mb-5 sm:mb-12">
          <h1 className="text-xl md:text-4xl 2xl:text-4xl font-semibold">{t('servicesWeOffer')}</h1>
        </div>
        <div className="sm:flex sm:justify-center sm:items-start border-y border-[#A8A8A8]">
          <div className="sm:w-1/2 sm:h-full sm:pr-12 py-3 sm:py-8 sm:border-r border-[#A8A8A8]">
            <div className="text-xl md:text-4xl 2xl:text-4xl font-semibold mb-2 sm:mb-3">
              <h1>{t('howWeWork')}</h1>
            </div>
            <div className="text-[text text-sm md:text-base xl:text-xl mb-3">
              <p className="leading-6 md:leading-8 xl:leading-10">{t('howWeWorkDescription1')}</p>
              <p className="leading-6 md:leading-8 xl:leading-10">{t('howWeWorkDescription2')}</p>
            </div>
            <div className="w-64 h-12 xl:w-64 xl:h-14 mb-2 rounded-xl bg-button hover:bg-hoverButton text-white text-lg font-semibold">
              <Link
                className="flex justify-center items-center w-64 h-12 xl:w-64 xl:h-14"
                to={'/services'}
              >
                {t('viewAll')}
              </Link>
            </div>
          </div>

          <div className="sm:w-1/2">
            {sections.map((section) => (
              <div key={section.id} className="border-b border-[#A8A8A8]">
                <div
                  onClick={() => handleSetIndex(section.id)}
                  className="flex items-center text-lg sm:text-xl md:text-2xl xl:text-3xl h-12 sm:h-16 xl:h-20 cursor-pointer"
                >
                  <span className={`m-5 rotate-icon ${activeIndex === section.id ? 'rotate' : ''}`}>
                    {activeIndex === section.id ? '×' : '+'}
                  </span>
                  {t(section.title)}
                </div>

                <div
                  className={clsx(
                    'overflow-hidden transition-all duration-500',
                    activeIndex === section.id ? 'max-h-screen' : 'max-h-0'
                  )}
                >
                  {activeIndex === section.id && (
                    <div className="p-4 pl-14 mb-3 sm:pl-16 sm:mb-5 text-text text-sm md:text-base xl:text-xl">
                      <p className="leading-6 md:leading-8 xl:leading-10">{t(section.content)}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
