import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const SEO = () => {
  const { t } = useTranslation('seo');
  useLocation();

  const currentUrl = window.location.origin + window.location.pathname + window.location.hash;

  return (
    <Helmet>
      <title>{t('title')}</title>
      <meta name="description" content={t('description')} />
      <link rel="alternate" href={`${currentUrl}?lng=en`} hrefLang="en" />
      <link rel="alternate" href={`${currentUrl}?lng=pl`} hrefLang="pl" />
    </Helmet>
  );
};
