import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ourMissionImg from '@/assets/ourMission.png';
import ourVisionImg from '@/assets/ourVision.png';
import theBeginningsImg from '@/assets/theBeginnings.png';

export const TheBeginnings = () => {
  const { t } = useTranslation('whoWeAre');

  return (
    <div className="flex justify-center w-auto font-sans my-5 px-5">
      <div className="grid lg:grid-cols-2 gap-4 xl:gap-8 w-5/6">
        <div className="grid lg:grid-rows-3 gap-4 order-last lg:order-first justify-self-end 2xl:w-5/6">
          <div className="flex items-center lg:items-end -order-3">
            <img src={theBeginningsImg} alt="The beginnings" />
          </div>
          <div className="flex items-center -order-1">
            <img src={ourMissionImg} alt="Our Mission" />
          </div>
          <div className="my-5 lg:my-0 -order-2 lg:-order-1">
            <div className="text-3xl md:text-4xl wxl:text-5xl font-bold lg:mb-4">
              <h1>{t('ourMission')}</h1>
            </div>
            <div className="pt-3 pb-5 lg:pb-10 text-text text-sm md:text-base lg:text-xl">
              <p className="leading-8 xl:leading-10">{t('weDeliver')}</p>
              <Link
                className="flex justify-center items-center sm:w-96 w-80 h-14 px-3 my-3 rounded-xl bg-button hover:bg-hoverButton text-white text-center text-lg font-semibold"
                to={'/contact'}
              >
                {t('getOffer')}
              </Link>
            </div>
          </div>
        </div>

        <div className="lg:grid lg:grid-rows-3 gap-4 2xl:w-5/6">
          <div className="my-5 lg:my-0">
            <div className="flex items-end lg:items-center text-3xl md:text-4xl 2xl:text-5xl font-bold lg:h-1/4">
              <h1>{t('theBeginnings')}</h1>
            </div>
            <div className="lg:h-2/4 text-text text-sm md:text-base lg:text-xl">
              <p className="leading-8 xl:leading-10">{t('beginningsDescription')}</p>
              <Link
                className="flex justify-center items-center sm:w-96 w-80 h-14 px-3 my-3 rounded-xl bg-button hover:bg-hoverButton text-white text-center text-lg font-semibold"
                to={'/contact'}
              >
                {t('getOffer')}
              </Link>
            </div>
          </div>

          <div className="flex items-center">
            <img src={ourVisionImg} alt="Our Vision" />
          </div>
          <div className="my-5 lg:my-0">
            <div className="text-3xl md:text-4xl wxl:text-5xl font-bold lg:mb-4">
              <h1>{t('ourVision')}</h1>
            </div>
            <div className="pt-3 pb-5 lg:pb-10 text-text text-sm md:text-base lg:text-xl">
              <p className="leading-8 xl:leading-10">{t('benefitFromApp')}</p>
              <Link
                className="flex justify-center items-center sm:w-96 w-80 h-14 px-3 my-3 rounded-xl bg-button hover:bg-hoverButton text-white text-center text-lg font-semibold"
                to={'/contact'}
              >
                {t('getOffer')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
