import { zodResolver } from '@hookform/resolvers/zod';
import * as Dialog from '@radix-ui/react-dialog';
import { collection, addDoc } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { z } from 'zod';

import { ServiceType } from './ServiceTypes';
import { SuccessDialogService } from './SuccessDialogService';

import { database } from '@/utils/firebaseConfig';

interface Props {
  open: boolean;
  onClose: () => void;
  service: ServiceType;
}

export const ServicePopup: React.FC<Props> = ({ open, onClose, service }) => {
  const { t } = useTranslation('servicePopup');

  const serviceTranslationKey = {
    [ServiceType.WEB_APP]: 'webApp',
    [ServiceType.MOBILE_APP]: 'mobileApp',
    [ServiceType.DESKTOP_APP]: 'desktopApp',
    [ServiceType.PLUGIN_DEVELOPMENT]: 'pluginDevelopment',
    [ServiceType.INTEGRATIONS]: 'integrations',
    [ServiceType.SOFTWARE_MAINTENANCE]: 'softwareMaintenance',
  };

  const translatedServiceName = t(serviceTranslationKey[service]);

  const schema = z.object({
    companyName: z.string().min(5, t('requiredData')),
    email: z.string().email(t('requiredEmail')).nonempty(t('invalidEmail')),
    phoneNumber: z.string().optional(),
    query: z.string().min(10, t('requiredInquiry')),
  });

  type FormValues = z.infer<typeof schema>;

  const [activeStep, setActiveStep] = useState(0);
  const [successOpen, setSuccessOpen] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const onSubmit = async (data: FormValues) => {
    try {
      const serviceName = t(serviceTranslationKey[service]);
      const docRef = await addDoc(collection(database, 'service-requests'), {
        ...data,
        service: serviceName,
        createdAt: new Date(),
      });
      console.log('Document written with ID: ', docRef.id);
      setSuccessOpen(true);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <>
      <Dialog.Root open={open} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-50 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity" />
          <Dialog.Content className="fixed top-1/2 left-1/2 z-50 w-[90vw] lg:w-[50vw] p-6 bg-white rounded-md shadow-md transform -translate-x-1/2 -translate-y-1/2 focus:outline-none">
            <div className="flex justify-between w-full pb-3">
              <h3 className="text-xl font-semibold">{t('title')}</h3>
              <button
                onClick={onClose}
                className="text-hoverButton hover:text-button"
                aria-label="Close"
              >
                <FaTimes color="currentColor" className="h-6 w-6"></FaTimes>
              </button>
            </div>

            <div className="mb-4">
              <div className="flex justify-between">
                <span
                  className={`font-semibold ${activeStep === 0 ? 'text-button' : 'text-gray-300'}`}
                >
                  {t('step')} 1
                </span>
                <span
                  className={`font-semibold ${activeStep === 1 ? 'text-button' : 'text-gray-300'}`}
                >
                  {t('step')} 2
                </span>
              </div>
              <div className="w-full bg-gray-200 h-1 rounded mt-2">
                <div
                  className={`h-full bg-button rounded ${activeStep === 0 ? 'w-1/2' : 'w-full'}`}
                ></div>
              </div>
            </div>

            {activeStep === 0 && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <p className="mb-8">
                  {t('service')}
                  <span className="font-bold"> {translatedServiceName}</span>
                </p>

                <div className="mb-4">
                  <label className="block text-gray-700 mb-1">{t('data')}</label>
                  <Controller
                    name="companyName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className={`w-full p-2 border ${errors.companyName ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-button`}
                        />
                        {errors.companyName && (
                          <span className="text-red-500 text-sm">{errors.companyName.message}</span>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-1">{t('email')}</label>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className={`w-full p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-button`}
                        />
                        {errors.email && (
                          <span className="text-red-500 text-sm">{errors.email.message}</span>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-1">{t('tel')}</label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-button"
                        />
                      </>
                    )}
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={handleNext}
                    className="px-4 py-2 bg-button text-white rounded hover:bg-hoverButton"
                  >
                    {t('next')}
                  </button>
                </div>
              </form>
            )}

            {activeStep === 1 && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-1">{t('inquiry')}</label>
                  <Controller
                    name="query"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <textarea
                          {...field}
                          className={`w-full p-2 border ${errors.query ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-button`}
                          rows={8}
                        ></textarea>
                        {errors.query && (
                          <span className="text-red-500 text-sm">{errors.query.message}</span>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={handleBack}
                    className="px-4 py-2 bg-white text-button rounded hover:bg-hoverButton hover:text-white"
                  >
                    {t('back')}
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-button text-white rounded hover:bg-hoverButton"
                  >
                    {t('send')}
                  </button>
                </div>
              </form>
            )}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      <SuccessDialogService open={successOpen} onClose={() => setSuccessOpen(false)} />
    </>
  );
};

ServicePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  service: PropTypes.oneOf(Object.values(ServiceType)).isRequired,
};
