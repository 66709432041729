import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import i18n from '@/translations/i18n';

interface Language {
  code: string;
  name: string;
}

export const LanguagePicker: React.FC = () => {
  const languages: Language[] = [
    { code: 'pl', name: 'PL' },
    { code: 'en', name: 'EN' },
  ];

  const lastSelectedLanguage = i18n.language;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    languages.find((lang) => lang.code === lastSelectedLanguage) || languages[0]
  );

  const handleSelect = (lang: Language) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang.code);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none uppercase"
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedLanguage.name}
          <FaChevronDown className="ml-2 mt-1" />
        </button>
      </div>

      {isOpen && (
        <div className="absolute right-0 bottom-8 mb-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {languages.map((lang) => (
              <button
                key={lang.code}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 uppercase"
                onClick={() => handleSelect(lang)}
              >
                {lang.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguagePicker;
