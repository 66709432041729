import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import contactUs from '@/assets/contactUs.svg';

import '../routes/Home.css';

export const ContactSection = () => {
  const { t } = useTranslation('home');

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'ContactPage',
    mainEntity: {
      '@type': 'ContactForm',
      name: t('contactUs'),
      description: t('weLookForward'),
      action: {
        '@type': 'CommunicateAction',
        target: 'https://www.intechnity.com//#/contact', // Replace with your actual URL
        expectsAcceptanceOf: {
          '@type': 'Offer',
          name: 'Contact Submission',
        },
      },
    },
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      <div className="grid grid-col-1 px-10 2xl:px-20 py-16 lg:my-28 bg-gradient-to-r   from-background1 to-background2">
        <div className="flex justify-center items-center text-xl md:text-4xl 2xl:text-4xl font-semibold sm:my-5">
          <h1>{t('contactUs')}</h1>
        </div>
        <div className="flex justify-center items-center text-text text-sm md:text-base xl:text-xl my-5 sm:px-32 lg:px-40 2xl:px-64">
          <div className="grid grid-col-1">
            <p className="leading-10 text-center lg:px-14 2xl:px-60">{t('weLookForward')}</p>
          </div>
        </div>
        <div className="flex justify-center items-center mb-10 sm:my-10">
          <div className="w-48 h-12 xl:w-48 xl:h-14 mb-2 rounded-xl bg-button hover:bg-hoverButton text-white text-lg font-semibold">
            <Link
              className="flex justify-center items-center w-48 h-12 xl:w-48 xl:h-14"
              to={'/contact'}
            >
              {t('connectWithUs')}
            </Link>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <img src={contactUs} alt="Contact Us" className="w-1/2" />
        </div>
      </div>
    </>
  );
};
