import React, { useState, useEffect } from 'react';
import { FaChevronUp } from 'react-icons/fa';

interface ButtonScrollTopProps {
  mainRef: React.RefObject<HTMLElement>;
}

export const ButtonScrollTop: React.FC<ButtonScrollTopProps> = ({ mainRef }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (mainRef.current) {
      const scrollTop = mainRef.current.scrollTop;
      if (scrollTop > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  const scrollToTop = () => {
    if (mainRef.current) {
      mainRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.addEventListener('scroll', toggleVisibility);

      return () => {
        if (mainRef.current) {
          mainRef.current.removeEventListener('scroll', toggleVisibility);
        }
      };
    }
  }, [mainRef]);

  return (
    <button
      onClick={scrollToTop}
      className="fixed bottom-3 left-3 z-50 p-3 bg-gray-400 text-white rounded-full"
      style={{ display: isVisible ? 'block' : 'none' }}
      aria-label="Scroll to top"
    >
      <FaChevronUp />
    </button>
  );
};
