import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import emailImg from '@/assets/emailImg.svg';
import locationImg from '@/assets/locationImg.svg';
import phoneImg from '@/assets/phoneImg.svg';

export const Contact = () => {
  const { t } = useTranslation('contact');

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'ContactPage',
    mainEntity: {
      '@type': 'Organization',
      name: 'Intechnity',
      url: 'https://intechnity.com',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '+48 536 393 755',
          contactType: 'Customer Service',
          areaServed: ['PL', 'DE', 'FR', 'ES', 'IT', 'GB', 'US', 'NO'],
          availableLanguage: ['Polish', 'German', 'English'],
        },
        {
          '@type': 'ContactPoint',
          email: 'contact@intechnity.com',
          contactType: 'Customer Service',
          areaServed: ['PL', 'DE', 'FR', 'ES', 'IT', 'GB', 'US', 'NO'],
          availableLanguage: ['Polish', 'German', 'English'],
        },
      ],
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'ul. Technologiczna 2',
        addressLocality: 'Opole',
        postalCode: '45-839',
        addressCountry: 'PL',
      },
    },
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      <div className="w-auto font-sans">
        <div className="flex justify-center bg-getInTouchImg bg-cover bg-bottom w-full h-96">
          <div className="flex items-center text-4xl md:text-5xl xl:text-7xl font-semibold text-white uppercase tracking-widest">
            <h1>{t('getInTouch')}</h1>
          </div>
        </div>
        <div className="grid sm:grid-cols-3 gap-4 my-10 mx-10 lg:mx-20">
          <div className="flex justify-center bg-backgroundItems rounded-xl py-5">
            <div className="grid grid-cols-1 justify-items-center">
              <div className="flex justify-center items-center bg-button rounded-full h-20 w-20">
                <img src={phoneImg} alt="Phone" />
              </div>
              <div className="font-semibold mt-5">
                <a
                  href="tel:+48536393755"
                  className="pl-2 py-3 text-lg hover:text-button font-sans"
                >
                  +48 536 393 755
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-center bg-backgroundItems rounded-xl py-5">
            <div className="grid grid-cols-1 justify-items-center">
              <div className="flex justify-center items-center bg-button rounded-full h-20 w-20">
                <img src={locationImg} alt="Location" />
              </div>
              <div className="font-semibold mt-5">
                <a
                  href="https://maps.app.goo.gl/7i6ZPQZL2s4V7dg16"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pl-2 py-3 text-lg hover:text-button font-sans"
                >
                  ul. Technologiczna 2, 45-839 Opole
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-center bg-backgroundItems rounded-xl py-5">
            <div className="grid grid-cols-1 justify-items-center">
              <div className="flex justify-center items-center bg-button rounded-full h-20 w-20">
                <img src={emailImg} alt="" />
              </div>
              <div className="font-semibold mt-5">
                <a
                  href="mailto:contact@intechnity.com"
                  className="pl-2 py-3 text-lg hover:text-button font-sans"
                >
                  contact@intechnity.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
