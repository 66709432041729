import { AnimatePresence, motion } from 'framer-motion';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import { MainLayout } from './MainLayout';

import { Contact } from '@/features/contact';
import { Home } from '@/features/home';
import { IntechnityOne } from '@/features/intechnity-one';
import { Services } from '@/features/services';
import { WhoWeAre } from '@/features/whoWeAre';

const publicRoutes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'services',
        element: <Services />,
      },
      {
        path: 'who-we-are',
        element: <WhoWeAre />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'intechnity-one',
        element: <IntechnityOne />,
      },
      // TODO - enable pages
      // {
      //   path: 'portfolio',
      //   element: <Portfolio />,
      // },
      // {
      //   path: 'portfolio/:title',
      //   element: <ProjectDetails />,
      // },
      // {
      //   path: 'products',
      //   element: <Products />,
      // },
      // {
      //   path: 'products/:title',
      //   element: <ProductDetails />,
      // },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];

export const AppRoutes = () => {
  const element = useRoutes(publicRoutes);
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        {element}
      </motion.div>
    </AnimatePresence>
  );
};
