import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAMbnRh5Tn9BeOk9-V0WMrxwxWCyNflhVw',
  authDomain: 'intechnity-homepage.firebaseapp.com',
  databaseURL: 'https://intechnity-homepage-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'intechnity-homepage',
  storageBucket: 'intechnity-homepage.appspot.com',
  messagingSenderId: '1001026553264',
  appId: '1:1001026553264:web:3e0ac814a2e6a3ba90c275',
  measurementId: 'G-MX45N295BC',
};

const app = initializeApp(firebaseConfig);

export const database = getFirestore(app);
