import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { OurTeam } from '../components/OurTeam';
import { TheBeginnings } from '../components/TheBeginnings';
import { Totals } from '../components/Totals';

import whoWeAreImg from '@/assets/whoWeAre.svg';

export const WhoWeAre = () => {
  const { t } = useTranslation('whoWeAre');

  return (
    <div className="w-auto font-sans my-5">
      <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 sm:gap-4 my-5 sm:my-20 2xl:my-24 px-10 sm:px-16">
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 gap-5 sm:gap-10 2xl:pl-28">
            <div className="text-3xl md:text-4xl xl:text-5xl font-bold">
              <h1>{t('weAre')}</h1>
            </div>
            <div className="text-text text-sm md:text-base xl:text-xl">
              <p className="leading-10">{t('includingWhoWeAre')}</p>
            </div>
            <div className="flex w-auto">
              <Link
                className="flex justify-center items-center sm:w-96 w-80 h-14 px-3 rounded-xl bg-button hover:bg-hoverButton text-white text-center text-lg font-semibold"
                to={'/contact'}
              >
                {t('getOffer')}
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-end 2xl:justify-center">
          <img src={whoWeAreImg} alt="Who We Are - Intechnity" />
        </div>
      </div>

      <Totals />
      <TheBeginnings />

      {/* TODO enable content <OurTeam /> */}
    </div>
  );
};
