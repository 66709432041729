import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ContactSection } from '../components/ContactSection';
import { FAQSectionHome } from '../components/FAQSection';
import { LetsMakeHome } from '../components/IntroSection';
import { PoweringCompanies } from '../components/PoweringCompanies';
import { ServicesHome } from '../components/ServicesHome';

import './Home.css';

import homePromoAnimation from '@/assets/homePromoAnimation.json';
import imageImg from '@/assets/image.png';
import offerImg from '@/assets/offer.svg';

export const Home = () => {
  const { t } = useTranslation('home');

  return (
    <div className="w-auto font-sans my-5">
      <LetsMakeHome />

      <div className="flex justify-center items-center w-auto my-5">
        <div className="flex justify-center w-full mx-5 md:mx-10 lg:mx-16 2xl:mx-32 h-fit  rounded-xl bg-[#F2F2F3] ">
          <img src={imageImg} className="w-5/6 md:w-auto" alt="Intechnity" />
        </div>
      </div>

      <PoweringCompanies />

      <div className="grid grid-cols-1 px-10 2xl:px-20 my-20 lg:my-28 ">
        <div className="lg:flex lg:justify-center lg:items-center">
          <div className="lg:w-1/2 2xl:w-[700px] px-10 2xl:px-16">
            <div className="flex justify-center items-center">
              <img src={offerImg} className="w-1/2 lg:w-fit" alt="What we offer - Intechnity" />
            </div>
          </div>

          <div className="lg:w-1/2 2xl:w-[800px]">
            <div className="text-xl md:text-4xl 2xl:text-4xl font-semibold my-5">
              <h1 className="leading-relaxed">{t('weOffer')}</h1>
            </div>
            <div className="text-text text-sm md:text-base xl:text-xl my-5">
              <p className="leading-10">{t('wantToAchieve')}</p>
              <p className="leading-10">{t('developmentTeam')}</p>
            </div>
            <div className="sm:w-96 w-80 px-3 h-16 xl:h-14 rounded-xl bg-button hover:bg-hoverButton text-white text-lg font-semibold">
              <Link
                className="flex justify-center items-center w-full h-16 xl:h-14 text-center"
                to={'/contact'}
              >
                {t('instantQuote')}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <ServicesHome />

      <div className="w-full my-16 lg:my-28 px-5 lg:px-20">
        <div className="flex justify-center items-center p-16">
          <Lottie animationData={homePromoAnimation} loop={true} autoplay={true} />
        </div>
      </div>

      {/* TODO - enable content */}
      {/* <FeaturedWorkHome /> */}

      <FAQSectionHome />

      <ContactSection />
    </div>
  );
};
