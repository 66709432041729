import { useTranslation } from 'react-i18next';

import fiverrServiceImg from '@/assets/fiverrServiceImg.svg';
import upworkServiceImg from '@/assets/upworkServiceImg.svg';

const data = [
  {
    imgSrc: upworkServiceImg,
    imgAlt: 'upwork',
    label: 'upwork',
    url: 'https://www.upwork.com/agencies/intechnity/',
  },
  {
    imgSrc: fiverrServiceImg,
    imgAlt: 'fiverr',
    label: 'fiverr',
    url: ' https://www.fiverr.com/intechnity',
  },
];

export const IntechnityOne = () => {
  const { t } = useTranslation('intechnityOne');

  return (
    <div className="w-auto font-sans">
      <div className="flex justify-center bg-intechnityOneImg bg-cover bg-bottom w-full h-96">
        <div className="flex items-center text-4xl md:text-5xl xl:text-7xl font-semibold text-white uppercase tracking-widest">
          <h1>{t('title')}</h1>
        </div>
      </div>

      <div className="flex justify-center items-center text-3xl xl:text-4xl font-semibold mt-16">
        <h1>{t('findUs')}:</h1>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mx-4 md:mx-16 my-16">
        {data.map((entry, index) => (
          <a
            href={entry.url}
            target="_blank"
            rel="noreferrer"
            key={index}
            className="flex justify-center bg-backgroundItems rounded-xl py-5"
          >
            <div className="grid grid-cols-1 justify-items-center">
              <div className="flex justify-center items-center bg-white rounded-xl h-16 w-16">
                <img src={entry.imgSrc} alt={t(entry.imgAlt)} className="w-32 h-32" />
              </div>
              <div className="font-semibold mt-5">
                <a className="pl-2 py-3 text-lg hover:text-[#1F5B48] font-sans">{t(entry.label)}</a>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};
