import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfo } from 'react-icons/fa';

import '../routes/Home.css';

const sections = [
  { id: 0, title: 'question1', content: 'answer1' },
  { id: 1, title: 'question2', content: 'answer2' },
  { id: 2, title: 'question3', content: 'answer3' },
  { id: 3, title: 'question4', content: 'answer4' },
  { id: 4, title: 'question5', content: 'answer5' },
  { id: 5, title: 'question6', content: 'answer6' },
  { id: 6, title: 'question7', content: 'answer7' },
  { id: 7, title: 'question8', content: 'answer8' },
  { id: 8, title: 'question9', content: 'answer9' },
  { id: 9, title: 'question10', content: 'answer10' },
  { id: 10, title: 'question11', content: 'answer11' },
  { id: 11, title: 'question12', content: 'answer12' },
];

export const FAQSectionHome = () => {
  const { t } = useTranslation('faq');
  const [activeIndex, setActiveIndex] = useState(null);

  const handleSetIndex = (id: number) => {
    setActiveIndex(id === activeIndex ? null : id);
  };

  return (
    <div className="grid grid-col-1 px-5 sm:px-10 2xl:px-28 py-16">
      <div className="flex justify-center items-center mb-5">
        <div className="flex items-center text-xs md:text-base 2xl:text-xl font-semibold uppercase bg-[#F2F2F3] px-7 py-3 rounded-full">
          <FaInfo color="#000000" />
          <h1 className="ml-2">{t('caption')}</h1>
        </div>
      </div>

      <div className="flex justify-center items-center mb-5 sm:mb-12">
        <h1 className="text-xl md:text-4xl 2xl:text-4xl font-semibold">{t('title')}</h1>
      </div>

      <div className="border-t border-[#A8A8A8]">
        <div>
          {sections.map((section) => (
            <div key={section.id} className="border-b border-[#A8A8A8]">
              <div
                onClick={() => handleSetIndex(section.id)}
                className="flex items-center text-lg sm:text-xl font-semibold h-12 md:h-16 xl:h-20 py-10"
              >
                <span
                  className={`m-5 cursor-pointer rotate-icon text-emerald-800 font-bold ${
                    activeIndex === section.id ? 'rotate' : ''
                  }`}
                >
                  {activeIndex === section.id ? '×' : '+'}
                </span>
                {t(section.title)}
              </div>

              <div
                className={clsx(
                  'overflow-hidden transition-all duration-500',
                  activeIndex === section.id ? 'max-h-screen' : 'max-h-0'
                )}
              >
                {activeIndex === section.id && (
                  <div className="pl-14 mb-3 sm:pl-16 sm:mb-5 text-sm md:text-base">
                    <p className="leading-6 md:leading-8 xl:leading-10">{t(section.content)}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
