import { useTranslation } from 'react-i18next';

import doneProjectsImg from '@/assets/doneProjects.svg';
import technologiesUsedImg from '@/assets/technologiesUsed.svg';
import totalClientsImg from '@/assets/totalClients.svg';

const statsData = [
  {
    imgSrc: doneProjectsImg,
    imgAlt: 'doneProjects',
    value: '30+',
    label: 'doneProjects',
  },
  {
    imgSrc: totalClientsImg,
    imgAlt: 'totalClients',
    value: '15+',
    label: 'totalClients',
  },
  {
    imgSrc: technologiesUsedImg,
    imgAlt: 'technologiesUsed',
    value: '10+',
    label: 'technologiesUsed',
  },
];

export const Totals = () => {
  const { t } = useTranslation('whoWeAre');

  return (
    <div className="w-auto font-sans my-5">
      <div className="my-10 sm:my-20 2xl:my-24 px-10 2xl:px-32">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 2xl:gap-10">
          {statsData.map((stat, index) => (
            <div key={index} className="flex items-center rounded-xl bg-[#F9F9F9] px-3 py-5">
              <div className="lg:w-1/4 2xl:w-1/5">
                <div className="flex justify-center items-center rounded-full bg-white w-16 h-16 sm:w-14 sm:h-14 xl:w-16 xl:h-16">
                  <img src={stat.imgSrc} className="w-8 h-8" alt={t(stat.imgAlt)} />
                </div>
              </div>
              <div className="grid grid-row-2 xl:gap-1 pl-3 lg:w-3/4 2xl:w-4/5">
                <div className="text-button text-2xl sm:text-xl xl:text-2xl font-semibold">
                  <h1>{stat.value}</h1>
                </div>
                <div className="text-text text-lg sm:text-base xl:text-lg">
                  <p>{t(stat.label)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
