import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogClose, DialogContent, DialogOverlay } from '@radix-ui/react-dialog';
import { addDoc, collection } from 'firebase/firestore';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { z } from 'zod';

import GetInTouchImg from '@/assets/getInTouch.svg';
import { database } from '@/utils/firebaseConfig';

export const GetInTouch = () => {
  const { t } = useTranslation('contact');

  const [dialogOpen, setDialogOpen] = useState(false);

  const createContactSchema = (t: TFunction) =>
    z.object({
      name: z.string().min(5, t('nameRequired')).max(50, t('nameMaxLength')),
      email: z.string().email(t('invalidEmail')).max(50, t('emailMaxLength')),
      phone: z.string().min(7, t('phoneRequired')).max(50, t('phoneMaxLength')),
      description: z
        .string()
        .min(7, t('descriptionRequired'))
        .max(10000, t('descriptionMaxLength')),
    });

  const contactSchema = createContactSchema(t);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof contactSchema>>({
    resolver: zodResolver(contactSchema),
  });

  const onSubmit = async (data: z.infer<typeof contactSchema>) => {
    try {
      await addDoc(collection(database, 'contact-requests'), {
        ...data,
        submittedAt: new Date(),
      });
      setDialogOpen(true);
    } catch (e) {
      console.error('Error adding document: ', e);
      alert(t('errorSendingMessage'));
    }
  };

  return (
    <div className="w-full py-15 my-5 px-10 md:px-20">
      <div className="w-full h-full">
        <div className="flex justify-center max-h-full items-center text-black">
          <div>
            <div className="flex justify-center text-4xl xl:text-5xl font-semibold">
              {t('getInTouch')}
            </div>
            <div className="text-center text-[#6B6B6B] text-base xl:text-lg tracking-wide pt-4">
              {t('getInTouchSubtitle')}
            </div>
          </div>
        </div>
        <div className=" flex justify-center items-start text-lg xl:pt-10">
          <div className="grid grid-rows-2 lg:flex lg:justify-center lg:items-start w-full xl:w-screen items-start">
            <div className="w-full row-span-2 lg:w-2/3 xl:w-1/2 mt-5 ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full lg:w-5/6">
                  <h2 className="py-3 font-semibold">{t('fullName')}</h2>
                  <input
                    {...register('name')}
                    type="text"
                    placeholder={t('enterFullName')}
                    autoComplete="name"
                    required
                    className="block w-full bg-[#F9F9F9] rounded-lg border-0 px-4 py-3 text-black ring-1 ring-inset ring-[#DDDDDD] placeholder:text-[#778844] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.name && <p className="text-red-500">{errors.name.message}</p>}
                </div>

                <div className="w-full lg:w-5/6">
                  <h2 className="py-3 font-semibold">{t('emailAddress')}</h2>
                  <input
                    {...register('email')}
                    type="email"
                    placeholder={t('enterEmailAddress')}
                    autoComplete="email"
                    required
                    className="block w-full bg-[#F9F9F9] rounded-lg border-0 px-4 py-3 text-black ring-1 ring-inset ring-[#DDDDDD] placeholder:text-[#778844] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.email && <p className="text-red-500">{errors.email.message}</p>}
                </div>

                <div className="w-full lg:w-5/6">
                  <h2 className="py-3 font-semibold">{t('phoneNumber')}</h2>
                  <input
                    {...register('phone')}
                    type="tel"
                    placeholder={t('enterPhoneNumber')}
                    autoComplete="tel"
                    required
                    className="block w-full bg-[#F9F9F9] rounded-lg border-0 px-4 py-3 text-black ring-1 ring-inset ring-[#DDDDDD] placeholder:text-[#778844] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.phone && <p className="text-red-500">{errors.phone.message}</p>}
                </div>

                <div className="w-full lg:w-5/6">
                  <h2 className="py-3 font-semibold">{t('description')}</h2>
                  <textarea
                    {...register('description')}
                    placeholder={t('whriteDescription')}
                    autoComplete="description"
                    required
                    rows={12}
                    className="w-full bg-[#F9F9F9] rounded-lg border-0 px-4 py-3 text-black ring-1 ring-inset ring-[#DDDDDD] placeholder:text-[#778844] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.description && (
                    <p className="text-red-500">{errors.description.message}</p>
                  )}
                </div>

                <div className="w-full lg:w-5/6 py-5">
                  <button
                    type="submit"
                    className="w-full font-medium rounded-lg py-1 xl:py-3 text-white bg-button hover:bg-hoverButton "
                  >
                    {t('sendMessage')}
                  </button>
                </div>
              </form>

              <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogOverlay className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
                <DialogContent className="fixed top-1/2 left-1/2 max-w-sm p-6 text-center bg-white rounded-lg shadow-lg transform -translate-x-1/2 -translate-y-1/2">
                  <FaCheck className="mx-auto h-12 w-12 text-green-500" />
                  <h2 className="text-xl text-black font-semibold">
                    {t('messageSentSuccessfully')}
                  </h2>
                  <p className="mt-4 text-black">{t('messageSentSuccessfullyContent')}</p>
                  <DialogClose asChild>
                    <button
                      className="mt-6 w-full bg-button text-white rounded-lg py-2 hover:bg-hoverButton"
                      onClick={() => setDialogOpen(false)}
                    >
                      {t('close')}
                    </button>
                  </DialogClose>
                </DialogContent>
              </Dialog>
            </div>

            <div className="flex justify-center w-full lg:w-1/3 pt-5 mb-5 lg:pt-0">
              <div className="md:w-96">
                <img src={GetInTouchImg} className="w-full lg:w-96" alt="Get In Touch"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
